import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/contactUs/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Marketing Leads API
export const getMarketingLeads = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/marketingLeads`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getMarketingLeadsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/marketingLeads/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editMarketingLeads = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/marketingLeads/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteMarketingLeads = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/marketingLeads/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Landing Page Form API
export const getGoogleFormLeadsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/googleServices`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getGoogleFormLeadById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/googleServices/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editGoogleFormLeadById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/googleServices/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteGoogleFormLeadById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/googleServices/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


// Blogs API
export const addBlog = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blog`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlog = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog/`, + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlog = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blog/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs Category API
export const addBlogCategory = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blogCategory`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blogCategory`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlogCategory = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blogCategory/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlogCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blogCategory/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Career API
export const getCareersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/career`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCareerById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/career/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editCareerById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/career/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteCareerById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/career/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Marketing Message Status GET API
export const getMarketingMessage = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/marketingSentStatus`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



// Ad Landing Page Leads API
export const getAdLandingPage = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/adLandingpage`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAdLandingPageById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/adLandingpage/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editAdLandingPage = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/adLandingpage/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteAdLandingPage = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/adLandingpage/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};