/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import moment from 'moment'

/* ICON IMPORTS */
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import {
  deleteGoogleFormLeadById,
  editGoogleFormLeadById,
  getGoogleFormLeadsList,
} from "../../../service/api";
import { useEffect } from "react";

const findUpper = (string) => {

  let extractedString = [];

  let splittedString = string?.split(" ");
  let string1 = splittedString?.[0];
  let string2 = splittedString?.[1];

  function extractLetter(string) {
    for (var i = 0; i < string.length; i++) {
      if (
        string?.charAt(i) !== " "
      ) {
        return string?.charAt(i)?.toUpperCase()
      }
    }
  }

  extractedString[0] = extractLetter(string1);
  if (string2) {
    extractedString[1] = extractLetter(string2);
  }

  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

/* Validation Schema  */
const validationSchema = yup.object().shape({
  name: yup.string().required("First Name is Required"),
  email: yup.string().email().required("Email Address is Required"),
  phone: yup.number().required("Phone Number is Required"),
  message: yup.string().required("Message is Required"),
  subject: yup.string().required("Subject is Required"),
  formFor: yup.string().required("Form For is Required"),
  status: yup.boolean()
});
const Users = () => {
  /* MODAL STATES */
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          {row?.name ? <div className="avatar">{findUpper(row?.name)}</div> : ''}

          <h5>{row?.name} </h5>
        </div>
      ),
    }, {
      name: "Phone",
      selector: (row) => <span className="light-text">{row.phone}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "Query For",
      selector: (row) => <span className="light-text capitalize">{row.formFor === 'googleWorkSpace' ? 'Google Workspace' : "Google Cloud Platform"}</span>,
    },
    {
      name: "Created At",
      selector: (row) => <span className="light-text">{moment(row?.createdAt).format('MMM, DD YYYY, hh:mm A')}</span>,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditData(row);
              setEditModal(!editModal)
            }}
          />
          <DeleteIcon color="#8094AE" size="20" onClick={() => {
            setDeleteModal(!deleteModal);
            setDeleteId(row?._id);
          }} className="action-btn" />
        </div>
      ),
    }
  ];

  const [loading, setLoading] = useState(false);
  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return <div className="pagination-loading-screen">
      <p>Please wait!</p>
      <p>We are fetching data </p>
    </div>
  }

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fetchData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [search, setSearch] = useState(null);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e)
  };
  const handlePageChange = async (e) => {
    setPage(e)
  };

  const [viewData, setViewData] = useState(null);
  const getGoogleFormLeadsListData = () => {
    let params;
    console.log("search", search)
    if (search) {
      params = {
        page: page,
        sizePerPage: sizePerPage,
        search
      };
    } else {
      params = {
        page: page,
        sizePerPage: sizePerPage
      };
    }
    setLoading(true)
    getGoogleFormLeadsList(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      }).finally(() => {
        setLoading(false)
      })
      .catch((error) => {
        console.log("Error While Fetching Google Workspace Contact Us  List", error);
      });
  }

  const [deleteId, setDeleteId] = useState(null);
  const handleDelete = () => {
    deleteGoogleFormLeadById(deleteId)
      .then((res) => {
        getGoogleFormLeadsListData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
      });
  };

  const [editData, setEditData] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    if (editData !== null) {
      Object.entries(editData).forEach((entry) => {
        const [key, value] = entry;
        if (validationSchema?.fields?.hasOwnProperty(key)) {
          formikEditForm.setFieldValue(key, value);
        }
      });
    }
  }, [editData]);

  /* FORM VALUES HANDLING */
  const formikEditForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      subject: "",
      formFor: "",
      status: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setEditLoading(true);
      console.log("values", values)
      editGoogleFormLeadById(editData?._id, values)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            setEditModal(false);
            getGoogleFormLeadsListData();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setEditLoading(false);
        });
    },
  });

  useEffect(() => {
    getGoogleFormLeadsListData()
  }, [page, sizePerPage, search])

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Google Workspace Contact Us  Page Leads</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your contacts us page leads and edit their information
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    console.log("text", e.target.value)
                    setSearch(e.target.value)
                  }}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* View MODAL */}
      <Modal
        centered
        backdrop="static"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Google Workspace Contact Us  Details</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p><strong>Name: </strong>{viewData?.name}</p>
          <p><strong>Email: </strong>{viewData?.email}</p>
          <p><strong>Phone: </strong>{viewData?.phone}</p>
          <p><strong>Subject: </strong>{viewData?.subject}</p>
          <p><strong>Message: </strong>{viewData?.message}</p>
          <p><strong>Query For: </strong>{viewData?.formFor === 'googleWorkSpace' ? 'Google Workspace' : "Google Cloud Platform"}</p>
          <p><strong>Created At: </strong> {moment(viewData?.createdAt).format('MMM, DD YYYY, hh:mm A')}</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setViewModal(!viewModal)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these contact details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => {
              setViewData(null)
              setDeleteModal(!deleteModal)
            }}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="md"
        show={editModal}
        onHide={() => setEditModal(!editModal)}
      >
        <form onSubmit={formikEditForm.handleSubmit} id="edit-form" className="add-user-form">
          <Modal.Header className="add-user-modal-header">
            <h5>Edit Details</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="First Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.name}
                    defaultValue={formikEditForm?.values?.name}
                  />
                  {formikEditForm.errors.name &&
                    formikEditForm.touched.name && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.name}
                      </small>
                    )}
                </div>
              </Col>

              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="placeholder@roundtechsquare.com"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.email}
                    defaultValue={formikEditForm?.values?.email}
                  />
                  {formikEditForm.errors.email &&
                    formikEditForm.touched.email && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.email}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    className="phone-control"
                    onChange={(e) => {
                      formikEditForm.setFieldValue('phone', e)
                    }}
                    value={formikEditForm?.values?.phone}
                    defaultValue={formikEditForm?.values?.phone}
                  />
                  {formikEditForm.errors.phone &&
                    formikEditForm.touched.phone && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.phone}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder="Message"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.message}
                    defaultValue={formikEditForm?.values?.message}
                  />
                  {formikEditForm.errors.message &&
                    formikEditForm.touched.message && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.message}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="subject">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.subject}
                    defaultValue={formikEditForm?.values?.subject}
                  />
                  {formikEditForm.errors.subject &&
                    formikEditForm.touched.subject && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.subject}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="formFor">
                    Form For ({editData?.formFor === 'googleWorkSpace' ? 'Google Workspace' : "Google Cloud Platform"})
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="formFor"
                    id="googleWorkSpace"
                    placeholder="Form For"
                    onChange={() => {
                      formikEditForm.setFieldValue("formFor", "googleWorkSpace")
                    }}
                    checked={formikEditForm?.values?.formFor === "googleWorkSpace"}
                  />
                  <label htmlFor="googleWorkSpace" className="mx-2">Google Workspace</label>
                  {formikEditForm.errors.formFor &&
                    formikEditForm.touched.formFor && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.formFor}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="formFor">
                    <br />
                  </label>
                  <br />
                  <input
                    type="radio"

                    name="formFor"
                    id="formFor"
                    placeholder="Form For"
                    onChange={() => {
                      formikEditForm.setFieldValue("formFor", "googleCloudPlatform")
                    }}
                    checked={formikEditForm?.values?.formFor === "googleCloudPlatform"}
                  />
                  <label htmlFor="googleCloudPlatform" className="mx-2">{" "} Google Cloud Platform</label>
                  {formikEditForm.errors.formFor &&
                    formikEditForm.touched.formFor && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.formFor}
                      </small>
                    )}
                </div>
              </Col>
              {console.log("formikEditForm.errors", formikEditForm.errors)}
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setEditModal(!editModal)}
            >
              Cancel
            </Button>
            {editLoading ? (
              <Button className="proceed-btn" type="button">
                Please Wait...
              </Button>
            ) : (
              <Button className="proceed-btn" type="submit">Save details</Button>
            )}

          </Modal.Footer>
        </form>
      </Modal>
    </section>
  );
};

export default Users;