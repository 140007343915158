/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import moment from 'moment'

/* ICON IMPORTS */
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import {
  getMarketingMessage,
} from "../../../service/api";
import { useEffect } from "react";

const findUpper = (string) => {

  let extractedString = [];

  let splittedString = string?.split(" ");
  let string1 = splittedString?.[0];
  let string2 = splittedString?.[1];

  function extractLetter(string) {
    for (var i = 0; i < string.length; i++) {
      if (
        string?.charAt(i) !== " "
      ) {
        return string?.charAt(i)?.toUpperCase()
      }
    }
  }

  extractedString[0] = extractLetter(string1);
  if (string2) {
    extractedString[1] = extractLetter(string2);
  }

  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

/* Validation Schema  */
const validationSchema = yup.object().shape({
  name: yup.string().required("First Name is Required"),
  email: yup.string().email().required("Email Address is Required"),
  phone: yup.number().required("Phone Number is Required"),
  companyName: yup.string().required("Company Name is Required"),
  findUs: yup.string().required("Find Us On is Required"),
  message: yup.string().required("Message is Required"),
  helpFor: yup.string().required("Help For is Required"),
  status: yup.boolean()
});
const Users = () => {
  /* MODAL STATES */
  const [viewModal, setViewModal] = useState(false);


  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          {row?.name ? <div className="avatar">{findUpper(row?.name)}</div> : ''}

          <h5>{row?.name} </h5>
        </div>
      ),
    }, {
      name: "Phone",
      selector: (row) => <span className="light-text">{row.phoneNumber}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text ">{row.email ? row?.email : "-"}</span>,
    },
    {
      name: "Status",
      selector: (row) => <span className={row?.twilioDetails?.status ? "text-success fw-bold " : " text-danger fw-bold "}>{console.log("status", row)}{row?.twilioDetails?.status === "queued" ? "Delivered" : row?.twilioDetails?.status === 400 ? "Unsubscribed" : "Failed"}</span>,
    },
    {
      name: "Created At",
      selector: (row) => <span className="light-text">{moment(row?.createdAt).format('MMM, DD YYYY, hh:mm A')}</span>,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
        </div>
      ),
    }
  ];

  const [loading, setLoading] = useState(false);
  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return <div className="pagination-loading-screen">
      <p>Please wait!</p>
      <p>We are fetching data </p>
    </div>
  }

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fetchData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [search, setSearch] = useState(null);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e)
  };
  const handlePageChange = async (e) => {
    setPage(e)
  };

  const [viewData, setViewData] = useState(null);
  const getLandingPageFormData = () => {
    let params;
    console.log("search", search)
    if (search) {
      params = {
        page: page,
        sizePerPage: sizePerPage,
        search
      };
    } else {
      params = {
        page: page,
        sizePerPage: sizePerPage
      };
    }
    setLoading(true)
    getMarketingMessage(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      }).finally(() => {
        setLoading(false)
      })
      .catch((error) => {
        console.log("Error While Fetching Marketing Message   List", error);
      });
  }


  useEffect(() => {
    getLandingPageFormData()
  }, [page, sizePerPage, search])

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Marketing Message Sent</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your marketing message sent status  and view  their information
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    console.log("text", e.target.value)
                    setSearch(e.target.value)
                  }}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* View MODAL */}
      <Modal
        centered
        backdrop="static"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Marketing Message   Details</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p><strong>Name: </strong>{viewData?.name}</p>
          <p><strong>Email: </strong>{viewData?.email ? viewData?.email : "-"}</p>
          <p><strong>Phone Number: </strong>{viewData?.phoneNumber ? viewData?.phoneNumber : '-'}</p>
          <p><strong>Address: </strong>{viewData?.address}</p>
          <p><strong>Url: </strong>{viewData?.url}</p>
          <p><strong>Message Body: </strong>{viewData?.messageBody}</p>
          <p><strong>Created At: </strong> {moment(viewData?.createdAt).format('MMM, DD YYYY, hh:mm A')}</p>
          <p><strong>Status: </strong>{viewData?.twilioDetails?.status ? "Delivered" : "Failed"}</p>

        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setViewModal(!viewModal)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Users;